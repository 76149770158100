<template>
  <div>

  </div>
</template>

<script>
import {form} from "@/common/rec/form";

export default {
  name: "Reception",
  created() {
    this.getRecTemp();
  },
  methods: {
    getRecTemp() {
      let url = '/admin/apply-temp?filter[is_oa]=3';
      this.$axios(url).then(res => {
        if(res.data && res.data.length > 0) {
          let temp = res.data[0];
          this.goTemp(temp)
        } else {
          let data = {
            name: '接待',
            detail: JSON.stringify(form),
            is_oa: 3
          }
          this.$axios({
            url: '/admin/apply-temp',
            method: 'POST',
            data
          }).then(res => {
            this.goTemp(res);
          })
        }
      })
    },
    goTemp(temp){
      this.$router.replace({name: 'Template', params: {id: temp.id}})
    }
  }
}
</script>

<style scoped>

</style>